<!-- 添加人员、修改人员 -->
<template>
  <WrapModal
    v-model:visible="show"
    formMode
    tooSmall
    :width="542"
    :modealTitle="isEdit ? '修改人员' : '添加人员'"
    :confirmLoading="confirmLoading"
    @ok="onOk"
    @cancel="onCancel"
  >
    <a-spin :spinning="spinning" :indicator="indicator">
      <a-form ref="formRef" :rules="rules" :model="formModel">
        <a-form-item label="姓名" name="staffName" class="form-item">
          <a-input :maxlength="30" placeholder="请输入姓名" v-model:value="formModel.staffName" />
        </a-form-item>
        <a-form-item label="账号" name="account" class="form-item">
          <a-input
            :maxlength="11"
            :disabled="disabledAccount"
            placeholder="请输入人员手机号用于账号登录"
            v-model:value="formModel.account"
          />
          <p class="tips">
            新账号无密码，人员可使用手机验证码登录后自行设置密码。如果该用户已存在其他企业，使用原密码。
          </p>
        </a-form-item>
        <!-- name="deptIdList" :autoLink="false"-->
        <a-form-item label="所属组织" ref="deptIdListRef" class="form-item">
          <DeptSelect v-model:deptList="deptList" @change="deptChange" :noPermissionControl="false" />
          <p class="tips">
            拖曳可调整部门优先级，排序第一的部门将作为主部门
          </p>
        </a-form-item>
        <!-- 账号为超管时不显示角色选项 -->
        <a-form-item label="所属角色" v-if="isShowRole">
          <div class="table" style="max-height: 250px;">
            <a-table
              :data-source="roleList"
              :rowKey="row => row.roleId"
              :columns="columns"
              :pagination="false"
              :scorll="{ y: 300 }"
            >
              <template #roleName="{text}">
                <a-tooltip placement="bottomLeft" v-if="text?.length > 10">
                  <template #title>
                    <span>{{ text || '--' }}</span>
                  </template>
                  {{ text || '--' }}
                </a-tooltip>
                <span v-else>{{ text || '--' }}</span>
              </template>
              <template #roleDataPermissionVOList="{text}">
                <a-tooltip placement="bottomLeft" v-if="text?.length > 10">
                  <template #title>
                    <span>{{ handleRoleText(text) || '--' }}</span>
                  </template>
                  {{ handleRoleText(text) || '--' }}
                </a-tooltip>
                <span v-else>{{ handleRoleText(text) || '--' }}</span>
              </template>
            </a-table>
          </div>
          <p class="tips">
            若未分配其他角色，普通员工默认拥有用印管理和审批中心两个基本权限
          </p>
        </a-form-item>
      </a-form>
    </a-spin>
  </WrapModal>
</template>

<script setup>
import { computed, defineProps, ref, watch, h, defineEmits, watchEffect } from 'vue'
import WrapModal from '@/components/WrapModal/index'
import { addUser, editUser, getDepartmentList, getRoleClassify } from '@/apis/businessManage'
import { useStore } from 'vuex'
import { cmsNotice } from '@/utils/utils'
import DeptSelect from './deptSelect.vue'
import { LoadingOutlined } from '@ant-design/icons-vue'
import { SET_USER_INFO } from '@/store/user/mutations-type'

const columns = [
  {
    title: '角色',
    dataIndex: 'roleName',
    width: '50%',
    slots: {
      title: 'roleName',
      customRender: 'roleName'
    }
  },
  {
    title: '当前数据权限',
    dataIndex: 'roleDataPermissionVOList',
    slots: {
      title: 'roleDataPermissionVOList',
      customRender: 'roleDataPermissionVOList'
    }
  }
]

const store = useStore()
// info: 人员信息，编辑时使用
// departmentId: 添加时，默认选中的组织
// const props = defineProps(['visible', 'info', 'departmentId'])
const props = defineProps(['visible', 'info', 'departmentInfo'])
const emits = defineEmits(['update:visible', 'changeInfo'])

// 弹窗显示隐藏 内部变了
const show = ref(false)

// 确定按钮 是否加载中
const confirmLoading = ref(false)
// 表单
const formRef = ref(null)
const rules = {
  staffName: [
    { required: true, message: '请输入姓名' },
    {
      validator: (rule, value) => {
        var reg = new RegExp(
          '[`~!@#$%^&*()=|{}":;.+_",\\[\\]<>《》/\\\\?～！@#%¥……&*|{}（）【】「」｜‘；：”“。，、？—]'
        )
        var reg1 = new RegExp("['\\-']")
        if (reg.test(value) || reg1.test(value)) {
          return Promise.reject('人员姓名不能有特殊字符')
        }
        return Promise.resolve()
      },
      trigger: 'change'
    }
  ],
  account: [
    { required: true, message: '请输入人员手机号用于账号登录' },
    {
      validator: (rule, value) => {
        if (!value) {
          return Promise.reject('请输入人员手机号用于账号登录')
        } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
          return Promise.reject('请输入正确手机号')
        }
        return Promise.resolve()
      },
      trigger: 'blur'
    }
  ]
  // deptIdList: [{ type: 'array', required: true, message: '请选择所属组织' }]
}
// 表单数据
const formModel = ref({
  staffName: undefined,
  account: undefined,
  deptIdList: null
})

// 所属部门 回显信息 数据格式：
// [{
//   departmentId: item.departmentId,
//   departmentName: item.departmentName
// }]
const deptList = ref([])
const deptIdListRef = ref(null)
// 所属组织的表单校验 change
const deptChange = () => {
  setTimeout(deptIdListRef.value.onFieldChange, 30)
}

// 表单接口请求中
const spinning = ref(false)
const indicator = h(LoadingOutlined, {
  style: { fontSize: '24px' },
  spin: true
})

// 是否为编辑弹窗
const isEdit = computed(() => !!props.info)
// 是否禁用修改手机号 只有超管能修改手机号，且不能修改超管自己的手机号
const disabledAccount = computed(() => {
  if (props.info) {
    //用户名账号不允许修改
    if (props.info.accountType == 2) return true
    //手机号
    if (store.state.user.userInfo.identity === 1) {
      return props.info.identity === 1
    }
    return true
  } else {
    return false
  }
})
// 角色数据
const roleList = ref([
  {
    roleId: 3,
    roleName: '系统-普通员工',
    roleDataPermissionVOList: [
      {
        enterpriseId: 355,
        staffId: null,
        roleId: 3,
        dataPermission: 1,
        dataId: null,
        dataType: null,
        sourceType: null,
        showInfo: '本人'
      }
    ]
  }
])
// 部门数据
const organizationalList = ref([])
//是否显示角色选项
const isShowRole = ref(true)

// 监听弹窗显示隐藏，外部内部变量同步
watch(
  () => props.visible,
  value => (show.value = value)
)

// 弹窗展示时，初始化数据
watch(show, value => {
  if (value) {
    if (isEdit.value) {
      //修改
      console.log('isEdit', props.info)
      formModel.value.account = props.info.mobile || undefined
      formModel.value.staffName = props.info.staffName
      roleList.value = props.info.roleVOList
      props.info.departmentRedisVOList.forEach(item => {
        // formModel.value.deptIdList.push(item.departmentId)
        if (item.departmentId !== 0) {
          deptList.value.push({ departmentId: item.departmentId, departmentName: item.departmentName })
        }
      })
      isShowRole.value = !(props.info.identity === 1)
    } else {
      //  else if (props.departmentId != 0) {
      //添加
      // formModel.value.deptIdList = [props.departmentId]
      formModel.value.deptIdList = deptList.value = props.departmentInfo
    }

    // getOrganizationalList()
  } else {
    // 关闭弹窗
    onCancel()
  }
})

//处理当前数据权限拼接
const handleRoleText = roleList => {
  console.log('哈哈哈哈哈', roleList)
  let str = ''
  if (Array.isArray(roleList)) {
    roleList.map((item, index) => {
      if (index == roleList.length - 1) {
        str += item.showInfo
      } else {
        str += `${item.showInfo}、`
      }
    })
  }
  return str
}

// 修改过所属组织后，更新表单中的 deptIdList
watch(
  deptList,
  () => {
    updateDeptIdList()
  },
  { deep: true }
)
// 更新表单中的 deptIdList
function updateDeptIdList() {
  formModel.value.deptIdList = deptList.value.map(e => e.departmentId)
}

// 点击确定
const onOk = async () => {
  confirmLoading.value = true
  console.log('formModel.value', formModel.value)
  //用户名账号不用校验(不符合手机号规则)
  let vaild = true
  if (!isEdit.value) {
    vaild = await formRef.value.validate().catch(() => (confirmLoading.value = false))
  }
  if (!vaild) return
  const value = formModel.value
  const params = {
    ...value
  }
  //修改区分用户名账号（字段不一样）
  if (isEdit.value) {
    params.staffName = value.staffName.trim()
    params.account = value.account
    // params.accountType = props.info.accountType
    params.accountType = 1
  } else {
    params.name = value.staffName.trim()
    params.mobile = value.account
  }
  isEdit.value && (params.id = props.info.staffId)
  console.log('人员 params', params)
  const api = isEdit.value ? editUser : addUser
  console.log('api', api)
  const res = await api(params)
  if (res.success) {
    cmsNotice('success', isEdit.value ? '修改成功' : '添加成功')

    // 更新右上角名称
    const userInfo = JSON.parse(localStorage.getItem('yda-admin-userInfo'))
    if (props.info?.userId === userInfo.userId) {
      userInfo.userName = params.staffName
      localStorage.setItem('yda-admin-userInfo', JSON.stringify(userInfo))
      store.commit(`user/${SET_USER_INFO}`, userInfo)
    }
    onCancel()
    confirmLoading.value = false
  } else {
    // onCancel()
    confirmLoading.value = false
  }
}

// 点击取消
const onCancel = () => {
  console.log('执行了')
  emits('update:visible', false)

  // setTimeout(() => {
  formModel.value = {
    staffName: undefined,
    account: undefined,
    deptIdList: null
  }
  roleList.value = [
    {
      roleId: 3,
      roleName: '系统-普通员工',
      roleDataPermissionVOList: [
        {
          enterpriseId: 355,
          staffId: null,
          roleId: 3,
          dataPermission: 1,
          dataId: null,
          dataType: null,
          sourceType: null,
          showInfo: '本人'
        }
      ]
    }
  ]
  deptList.value = []
  confirmLoading.value = false
  spinning.value = false
  emits('changeInfo')
}
</script>

<style lang="less" scoped>
.form-item {
  margin-bottom: 16px;
  :deep(.has-error) .dept-select {
    border-color: #ff4d4f;
  }
}
.tips {
  width: 400px;
  font-size: 12px;
  color: #999999;
  line-height: 17px;
  margin-top: 4px;
}

.table {
  :deep(.ant-table) {
    max-height: 250px;
    th {
      padding: 10px 16px 8px 16px;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.85);
    }
    td {
      padding: 10px 16px;
      color: rgba(0, 0, 0, 0.65);

      box-sizing: border-box;
    }
  }
}
</style>
